import React from 'react'

import Layout from '../components/layout/layout'
import PageHeader from '../components/layout/page-header'

const ContactPage = () => {
  return (
    <Layout>
      <PageHeader>Contact</PageHeader>

      <p>Under construction.</p>
      <p>coming soon.</p>
    </Layout>
  )
}

export default ContactPage
